<template>
    <div class="page-wrap">
        <div class="session-form-hold">
            <base-card>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="primary"
                />
                <v-card-text class="text-center">
                    <img
                        class="logo-effect"
                        :src="getPathLogoCompany()"
                        alt=""
                        width="200px"
                    >
                    <p class="text--disabled font-weight-medium mb-6 mt-4">
                        {{ $t('users.forgot.createNewPass') }}
                    </p>
                    <span
                        v-if="serverError"
                        class="red--text mb-2"
                    >
                        {{ serverError }}
                    </span>
                    <v-form
                        ref="form"
                        v-model="isValid"
                    >
                        <v-text-field
                            v-model="userInfo.password.value"
                            class="purple-input"
                            :label="$t('users.forgot.passwordNew')"
                            :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.validPassword]"
                            :type="showIcon ? 'text' : 'password'"
                            name="input-10-2"
                            :error-messages="userInfo.password.error"
                            @click:append="showIcon = !showIcon"
                            @keyup="userInfo.password.error = ''"
                        />

                        <v-text-field
                            v-model="userInfo.password_confirmation.value"
                            class="purple-input"
                            :label="$t('users.login.passwordConfirm')"
                            :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, /*rules.minLength8,*/ rules.passwordMatch]"
                            :type="showIcon ? 'text' : 'password'"
                            name="input-10-2"
                            :error-messages="userInfo.password_confirmation.error"
                            @click:append="showIcon = !showIcon"
                            @keyup="userInfo.password_confirmation.error = ''"
                        />
                    </v-form>
                    <v-btn
                        :style="`opacity: ${(!isValid && !serverCheck) ? '0.2' : '1'}`"
                        class="mb-4"
                        block
                        :color="getDefaultSecondaryColor()"
                        dark
                        @click="submit"
                    >
                        {{ $t('users.forgot.createNewPassButton') }}
                    </v-btn>
                    <div class="d-flex justify-around">
                        <v-btn
                            text
                            small
                            :color="getDefaultSecondaryColor()"
                            to="/app/sessions/sign-in-two"
                        >
                            {{ $t('users.login.signIn') }}
                        </v-btn>
                        <!--                        <v-btn
                            text
                            small
                            color="primary"
                        >
                            Create New Account
                        </v-btn>-->
                    </div>
                </v-card-text>
            </base-card>
        </div>
    </div>
</template>
<script>
import rules from '@/helpers/rulesValidate'
import axios from '@/plugins/axios'
import { mapActions } from 'vuex'
import companyMixin from "@/mixins/companyMixin";

export default {
    name: 'Forgot',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Forgot'
    },
    mixins:[companyMixin],
    data() {
        return {
            userInfo: this.loadUserInfo(),
            showIcon: false,
            isValid: true,
            serverCheck: false,
            serverError: '',
            loading: false,
            rules: {
                ...rules,
                passwordMatch: () => this.userInfo.password.value === this.userInfo.password_confirmation.value ||
                    this.$t('rules.passwordConfirmation')
            }
        }
    },
    methods: {
        ...mapActions(['setUser']),
        async canSignUp() {
            const configs = await axios
                .get('configs/1');

            let options = configs?.data?.data?.attributes?.options;
            if (options) {
                options = JSON.parse(options);
                return options.canRegister;
            }

            return false;
        },
        async submit() {
            if (!this.$refs.form.validate()) return false;

            this.loading = true;

            try {
                const result = await axios
                    .post("/resetPassword", {
                        token: this.userInfo.token,
                        password: this.userInfo.password.value,
                        password_confirmation: this.userInfo.password_confirmation.value
                    });

                this.loading = false;

                if (result.status === 202) {
                    this.$store.dispatch('snackbar/success', this.$t('users.forgot.passwordChangedOk'));
                    this.$router.push('/app/sessions/sign-in-two')
                }
            } catch (error) {
                /* console.log('error *******')
        console.log(error.response.data.errors) */

                this.loading = false;
                if (error.response.status === 406) {
                    this.serverError = this.$t('users.forgot.mustRestart');
                } else if (error.response.status === 422) {
                    var errors = error.response.data.errors;
                    // {"firstname":["The firstname must be a string."],"lastname":["The lastname must be a string."],"userid":["The userid must be a string."],"email":["The email must be a string.","The email must be a valid email address."],"pwd":["The pwd confirmation does not match."]}
                    if (errors.name) { this.userInfo.firstname.error = this.getServerValidation(errors.name); } // "The name field is required."
                    if (errors.lastname) { this.userInfo.lastname.error = this.getServerValidation(errors.lastname); }
                    if (errors.username) { this.userInfo.userid.error = this.getServerValidation(errors.username, 'username'); }
                    if (errors.email) { this.userInfo.email.error = this.getServerValidation(errors.email, 'email'); } // "The email has already been taken."
                    if (errors.password) { this.userInfo.password.error = this.getServerValidation(errors.password, 'password'); } //  "The password must be at least 6 characters."
                    if (errors.password) { // password_confirmation
                        this.userInfo.password_confirmation.error = this.getServerValidation(errors.password, 'password_confirmation'); // "The password confirmation does not match."
                    }
                } else if (error.response.status === 412) {
                    const errorS = error.response.data;
                    console.log('errorS ', errorS)
                    if (errorS) {
                        this.serverError = this.$t(`errors.${errorS}`);
                    }
                }
            }
            /* setTimeout(() => {
          console.log('done')
      }, 1000) */
        },
        getServerValidation(values, field = "") {
            for (let i = 0; i < values.length; i++) {
                const value = values[i];
                if (value) {
                    if (value.indexOf("required") !== -1) {
                        return this.$t('rules.required');
                    } else if (field === 'username' && value.indexOf("already been taken") !== -1) {
                        return this.$t('rules.usernameTaken');
                    } else if (field === 'email' && value.indexOf("already been taken") !== -1) {
                        return this.$t('rules.emailTaken');
                    } else if (field === 'password' && value.indexOf("password must be at least") !== -1) {
                        return this.$t('rules.password');
                    } else if (field === 'password_confirmation' && value.indexOf("password confirmation does not match") !== -1) {
                        return this.$t('rules.passwordConfirmation');
                    }
                }
            }

            return "";
        },
        loadUserInfo() {
            return {
                token: this.$route.query.token,
                password: {
                    value: "",
                    error: ""
                },
                password_confirmation: {
                    value: "",
                    error: ""
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.page-wrap {
    //background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
